<template>
    <b-modal
        id="modalProductCreateEdit"
        class="modal-demo"
        title-class="text-light"
        header-bg-variant="dark"
        header-text-variant="light"
        title-tag="h4"
        size="lg"
        centered
        @show="openModalHandler"
        @hidden="$emit('hidden')"
    >
        <template slot="modal-title">
            {{ modalTitle }}
        </template>
        <div class="custom-modal-text text-left">
            <div>
                <div class="form-group">
                    <label for="title">Hebrew name</label>
                    <input
                        id="title"
                        v-model="data.name"
                        :class="{ 'is-invalid': $v.data.name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter Hebrew Name"
                    />
                </div>
                <div class="form-group">
                    <label for="title">English name</label>
                    <input
                        id="title"
                        v-model="data.eng_name"
                        :class="{ 'is-invalid': $v.data.eng_name.$error }"
                        type="text"
                        class="form-control"
                        placeholder="Enter English Name"
                    />
                </div>
                <div class="form-group">
                    <label for="title">Order</label>
                    <input
                        id="title"
                        v-model="data.order"
                        :class="{ 'is-invalid': $v.data.order.$error }"
                        type="number"
                        min="0"
                        step="1"
                        class="form-control"
                        placeholder="Enter Product Order"
                    />
                </div>
            </div>
        </div>

        <template #modal-footer>
            <div class="col-12 d-flex justify-content-between">
                <button
                    type="button"
                    class="btn btn-danger waves-effect waves-light mr-2"
                    @click="hideModal"
                >
                    Cancel
                </button>
                <button
                    type="submit"
                    class="btn btn-success waves-effect waves-light"
                    @click="submitProductDataHandler"
                >
                    {{ buttonText }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
import 'flatpickr/dist/flatpickr.css';
import { required, integer, minValue } from 'vuelidate/lib/validators';

export default {
    props: {
        editingProduct: {
            type: Boolean,
            required: false,
            default: false
        },
        product: {
            type: Object,
            required: false,
            default: null
        },
        modalTitle: {
            type: String,
            required: false,
            default: 'Add Product Information'
        },
        buttonText: {
            type: String,
            required: false,
            default: 'Save'
        }
    },

    data() {
        const defaultData = {
            name: '',
            eng_name: '',
            order: 0
        };

        return {
            data: {
                ...defaultData
            },
            defaultData
        };
    },

    validations: {
        data: {
            name: {
                required
            },
            eng_name: {
                required
            },
            order: {
                required,
                integer,
                minValue: minValue(0)
            }
        }
    },

    methods: {
        hideModal() {
            this.$bvModal.hide('modalProductCreateEdit');

            this.data = {
                ...this.defaultData
            };
        },

        async openModalHandler() {
            await this.$nextTick();

            if (!this.editingProduct) {
                return;
            }

            const { name, eng_name, order } = this.product;

            this.data = {
                name,
                eng_name,
                order
            };
        },

        submitProductDataHandler() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            this.$emit(
                this.editingProduct ? 'edit-product' : 'create-product',
                this.data
            );

            this.$v.$reset();
            this.hideModal();
        }
    }
};
</script>
