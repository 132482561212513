<template>
    <div>
        <page-header :title="title" :items="items" />

        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="form-group">
                            <button
                                type="button"
                                class="btn btn-success waves-effect waves-light mb-3"
                                @click="showCreateModal"
                            >
                                Add new product
                            </button>
                            <h5> Selected Product </h5>
                            <b-form-select
                                v-model="selectedProduct"
                                :options="productsToDisplay"
                                text-field="name"
                                value-field="id"
                            />
                        </div>
                        <div v-if="productToUpdate.id" class="mt-4">
                            <edit-product
                                :product-to-update="productToUpdate"
                                @edit-product="showEditModal"
                                @delete-product="showDeleteModal"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <modal-product-create-edit
            :product="productToUpdate"
            :editing-product="editingProduct"
            @edit-product="editProductHandler"
            @create-product="createProductHandler"
        />

        <confirm-modal
            id="confirmProductDeleteModal"
            title="Do you want to delete this product?"
            @agree="deleteProductHandler"
        />
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import config from '@config';
import EditProduct from '@components/products/EditProduct';
import ConfirmModal from '@components/elements/ConfirmModal';
import ModalProductCreateEdit from '@components/products/ModalProductCreateEdit';

export default {
    page: {
        title: 'Products',
        meta: [{ name: 'description', content: config.description }]
    },

    components: {
        EditProduct,
        ConfirmModal,
        ModalProductCreateEdit
    },

    data() {
        return {
            title: 'Products',
            items: [
                {
                    text: 'NovoCare',
                    to: '/'
                },
                {
                    text: 'Products',
                    active: true
                }
            ],
            products: [],
            selectedProduct: null,
            productToUpdate: {},
            editingProduct: false
        };
    },

    computed: {
        productsToDisplay() {
            return this.products.filter(
                product =>
                    product.eng_name !== 'Saxenda®' &&
                    product.eng_name !== 'Wegovy®'
            );
        }
    },

    watch: {
        async selectedProduct() {
            if (!this.selectedProduct) {
                return;
            }

            this.productToUpdate = await this.getProduct(this.selectedProduct);
        }
    },

    async mounted() {
        await this.fetchProducts();
    },

    methods: {
        ...mapActions({
            getProducts: 'products/index',
            getProduct: 'products/show',
            createProduct: 'products/store',
            updateProduct: 'products/update',
            deleteProduct: 'products/destroy'
        }),

        async fetchProducts() {
            this.products = await this.getProducts();

            this.setSelectedProduct();
        },

        showCreateModal() {
            this.editingProduct = false;

            this.$bvModal.show('modalProductCreateEdit');
        },

        showEditModal() {
            this.editingProduct = true;

            this.$bvModal.show('modalProductCreateEdit');
        },

        showDeleteModal() {
            this.$bvModal.show('confirmProductDeleteModal');
        },

        async editProductHandler(data) {
            try {
                const updatedProduct = await this.updateProduct({
                    id: this.productToUpdate.id,
                    productData: data
                });

                this.productToUpdate = updatedProduct;

                const index = this.products.findIndex(
                    item => item.id === updatedProduct.id
                );

                this.products.splice(index, 1, updatedProduct);

                this.$bvToast.toast('Product has been updated.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async createProductHandler(data) {
            try {
                const response = await this.createProduct(data);

                if (!this.products.map(item => item.id).includes(response.id)) {
                    this.products = [response, ...this.products];
                }

                this.$bvToast.toast('Product has been added.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        async deleteProductHandler() {
            try {
                await this.deleteProduct(this.productToUpdate);

                this.products = this.products.filter(
                    item => item.id !== this.productToUpdate.id
                );

                this.setSelectedProduct();

                this.$bvToast.toast('Product has been deleted.', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                this.$bvToast.toast('Something went wrong!', {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        setSelectedProduct(index = 0) {
            while (
                index < this.products.length &&
                (this.products[index].eng_name === 'Wegovy®' ||
                    this.products[index].eng_name === 'Saxenda®')
            ) {
                index++;
            }

            this.selectedProduct =
                index < this.products.length ? this.products[index].id : null;
        }
    }
};
</script>
