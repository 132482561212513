<template>
    <div>
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-pills navtab-bg nav-justified mb-3">
                    <li
                        v-for="component in components"
                        :key="component.text"
                        class="nav-item"
                    >
                        <a
                            href="#"
                            data-toggle="tab"
                            aria-expanded="false"
                            class="nav-link"
                            :class="{
                                active:
                                    selectedComponent === component.component
                            }"
                            @click.prevent="
                                selectedComponent = component.component
                            "
                        >
                            {{ component.text }}
                        </a>
                    </li>
                </ul>

                <div v-show="selectedComponent === 'display'">
                    <display
                        :product="product"
                        @update-display="handleDisplayUpdate"
                    />
                </div>

                <div v-show="selectedComponent === 'permissions'">
                    <permissions
                        :product="product"
                        @update-permissions="handlePermissionsUpdate"
                    />
                </div>

                <div v-show="selectedComponent === 'checkboxes'">
                    <checkboxes
                        :product="product"
                        @add-checkbox="addCheckboxHandler"
                        @update-checkbox="updateCheckboxHandler"
                        @delete-checkbox="deleteCheckboxHandler"
                    />
                </div>

                <div v-show="selectedComponent === 'templates'">
                    <templates :product="product" />
                </div>

                <div v-show="selectedComponent === 'images'">
                    <images :product="product" />
                </div>

                <div v-show="selectedComponent === 'pdfs'">
                    <pdfs
                        :pdfs="product.pdfs"
                        :product-id="product.id"
                        @update-pdfs="updatePdfsHandler"
                    />
                </div>

                <div v-show="selectedComponent === 'videos'">
                    <videos
                        :videos="product.videos"
                        :product-id="product.id"
                        @update-videos="updateVideosHandler"
                    />
                </div>

                <div class="d-flex flex-wrap">
                    <div class="form-group col-12 d-flex justify-content-end">
                        <button
                            type="button"
                            class="btn btn-danger waves-effect waves-light mr-2"
                            @click="destroy"
                        >
                            Delete
                        </button>
                        <button
                            type="button"
                            class="btn btn-success waves-effect waves-light mr-2"
                            @click="edit"
                        >
                            Edit
                        </button>
                        <button
                            v-if="showSaveButton"
                            type="button"
                            class="btn btn-success waves-effect waves-light"
                            @click="save"
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { maxLength } from 'vuelidate/lib/validators';
import Pdfs from './Pdfs';
import Images from './Images';
import Videos from './Videos';
import Display from './Display';
import Templates from './Templates';
import Checkboxes from './Checkboxes';
import Permissions from './Permissions';

const maxCharacters = 990;

export default {
    components: {
        Pdfs,
        Images,
        Videos,
        Display,
        Templates,
        Checkboxes,
        Permissions
    },

    props: {
        value: {
            type: Object,
            required: false,
            default: () => ({})
        },
        productToUpdate: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    validations: {
        product: {
            message_template: {
                hebrew: { maxLength: maxLength(maxCharacters) },
                arabic: { maxLength: maxLength(maxCharacters) },
                russian: { maxLength: maxLength(maxCharacters) }
            }
        }
    },

    data() {
        return {
            product: { message_template: {} },
            selectedComponent: 'display',
            components: [
                {
                    text: 'Display',
                    component: 'display'
                },
                {
                    text: 'Permissions',
                    component: 'permissions'
                },
                {
                    text: 'Checkboxes',
                    component: 'checkboxes'
                },
                {
                    text: 'Message templates',
                    component: 'templates'
                },
                {
                    text: 'Images',
                    component: 'images'
                },
                {
                    text: 'PDFs',
                    component: 'pdfs'
                },
                {
                    text: 'Videos',
                    component: 'videos'
                }
            ]
        };
    },

    computed: {
        showSaveButton() {
            return !['pdfs', 'videos'].includes(this.selectedComponent);
        }
    },

    watch: {
        productToUpdate() {
            this.product = { ...this.productToUpdate };
        }
    },

    mounted() {
        this.product = { ...this.productToUpdate };
    },

    methods: {
        ...mapActions({
            updateProduct: 'products/update'
        }),

        async save() {
            this.$v.$touch();

            if (this.$v.$invalid) {
                return;
            }

            try {
                await this.updateProduct({
                    id: this.product.id,
                    productData: this.product
                });

                this.$bvToast.toast('Product has been updated', {
                    title: 'Success!',
                    variant: 'success',
                    solid: true
                });
            } catch (err) {
                console.error(err);

                let message = '';

                if (err.response.data.errors) {
                    err.response.data.errors.forEach(error => {
                        message += error + '. ';
                    });
                } else {
                    message = 'Something went wrong!';
                }

                this.$bvToast.toast(message, {
                    title: 'Error!',
                    variant: 'danger',
                    solid: true
                });
            }
        },

        edit() {
            this.$emit('edit-product');
        },

        destroy() {
            this.$emit('delete-product');
        },

        addCheckboxHandler(newCheckbox) {
            if (!this.product.checkboxes) {
                this.product.checkboxes = [];
            }

            this.product.checkboxes.push(newCheckbox);
        },

        updateCheckboxHandler(checkboxToUpdate) {
            const { index } = checkboxToUpdate;

            delete checkboxToUpdate.index;

            this.$set(this.product.checkboxes, index, checkboxToUpdate);
        },

        deleteCheckboxHandler(index) {
            this.product.checkboxes.splice(index, 1);
        },

        handleDisplayUpdate(display) {
            this.product = { ...this.product, ...display };
        },

        handlePermissionsUpdate(permissions) {
            this.product.permissions = permissions;
        },

        updatePdfsHandler(pdfs) {
            this.product.pdfs = pdfs;
        },

        updateVideosHandler(videos) {
            this.product.videos = videos;
        }
    }
};
</script>
