<template>
    <div>
        <b-form-checkbox
            v-model="productInfo"
            switch
            class="my-2"
            @change="updateDisplay"
        >
            Information of Products
        </b-form-checkbox>
        <b-form-checkbox
            v-model="nurseProductInfo"
            switch
            class="my-2"
            @change="updateDisplay"
        >
            Information for Nurses
        </b-form-checkbox>
        <b-form-checkbox
            v-model="setupTraining"
            switch
            class="my-2"
            @change="updateDisplay"
        >
            Referring The Patient For Support And Guidance
        </b-form-checkbox>
    </div>
</template>

<script>
export default {
    props: {
        product: {
            type: Object,
            required: false,
            default: () => ({})
        }
    },

    data() {
        return {
            productInfo: true,
            nurseProductInfo: true,
            setupTraining: true
        };
    },

    watch: {
        product() {
            const {
                in_product_information,
                in_nurse_product_information,
                in_setup_training
            } = this.product;

            this.productInfo = in_product_information;
            this.nurseProductInfo = in_nurse_product_information;
            this.setupTraining = in_setup_training;
        }
    },

    methods: {
        updateDisplay() {
            const updatedDisplay = {
                in_product_information: this.productInfo,
                in_nurse_product_information: this.nurseProductInfo,
                in_setup_training: this.setupTraining
            };

            this.$emit('update-display', updatedDisplay);
        }
    }
};
</script>
